

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body, button, input, textarea {
  font: 400 clamp(.8rem,1vw,1rem)/1 "Helvetica",sans-serif;
}
body {
  margin: 0% 10%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191919 !important;
}
.text-green{
  color: green;
}

p{
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: #fff;
  text-decoration: none;
}

h3 {
  display: block;
  font-size: 1.17em !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}
h4{
  font-size: 1rem;
color: white;
}
.iframe-plyr {
  height: 100vh;
  width: 100%;
  /* position: fixed; */
}
.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 0px !important;
  display: inline-block;
  height: 2px !important;
  margin: 6px !important;
  opacity: .7;
  padding: 0;
  position: relative;
  transition: transform .2s linear;
  width: 40px !important;
}
h3{
  font-size: 1.5rem;
color: white;
  line-height: 2.5 rem;
  margin: 2rem 0rem;
}
a{
text-decoration: none !important;
color: #000;


}
a {
  color: black !important;
  text-decoration: underline;
}
.catergory-bar {
  width: max-content !important;
}
.cat-btn{
  border: none;
  flex: 0 0 auto !important;
    font-size: 1.15rem !important;
    position: relative !important;
    padding: 1rem !important;
    opacity: .5 ;
    transition: opacity .2s !important;
    cursor: pointer !important;
    text-transform: capitalize !important;
   
}

.cat-active{
  font-weight: 700;
  opacity: 1;
  border-bottom: 1px solid;
}
.paginatin {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}
.header-top{
padding: 3%;
padding-top: 0px !important;
}


.allitems {
    font-size: 40px;
    font-family: 'Lobster Two', cursive;;
    font-style: italic;
    font-weight: 500;
    color: black;
    text-align: left;
    
   
}

.video-js .vjs-control-bar {
  
  background-color: transparent !important;
}

.video-js .vjs-tech {
  border-radius: 10px !important;
  background-color: #000;
}
.video-js {
 background-color: transparent !important;
}

.spinnerdiv {
  position: absolute;
  right: 50%;
  top: 50%;
}

.checkout-page{
display: flex;

}
.info{
  width: 50%;
  display: flex;
  flex-direction: column;
  
}
.summary{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
 

}
.border-none{
border: none !important;
}

.addtocart-btn{
  background: #f90;
  color: #fff;
  border: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: auto;
  margin: 0px 8%;
  display: flex;
  align-items: center;
  transition: background 0.2s;
}
.starts{
font-size: 16px !important;
line-height: .5 !important;

}

.start-span{
  opacity: .5;
}
/* .search-bar {
  float: right !important;
} */

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02{
  background-color: black !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: black !important;
}
.sidenav---sidenav---_2tBP *, .sidenav---sidenav---_2tBP *:before, .sidenav---sidenav---_2tBP *:after{
  background-color: transparent !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: black !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: black !important;
}
.sidenav---navicon---3gCRo{
opacity: 1 !important;
}
svg.star {
  font-size: 15px;
  color: rgb(255, 153, 0);
}

  .sidenav---sidenav---_2tBP {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1006;
    min-width: 64px;
    background: white !important;
    transition: min-width 0.2s;
}

.margin-top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.orders {
  width: 32%;
}
.order-container {
  display: flex;
  width: 80%;
  overflow: hidden;
}
.order-images img {
  height: 100px !important;
  flex-direction: row;
  display: flex;
}
.container.simmmilar {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: space-between;
}
.MuiBox-root{
  max-width: 100% !important;
 
}
 .del{
  color: #dc143c;
  font-size: 14px;
  text-decoration: line-through;
}
.productSingle__inner {
  display: block;
}

.productSingle__details .productSingle__footer {
  margin-top: auto;
}
.ep-button {
  padding: 20px 20px;
  display: flex;
  flex-flow: row wrap !important;
  justify-content: center;
  gap: 10px;
}
.episodes {
  background: black;
  margin: 0px 40px;
}

.btn-ep {
  padding: 5px !important;
  background: #5A2E98 !important;
  color: white !important;
  width: fit-content;
  font-weight: 400 !important;
  margin: 0px !important;
}
.activeep {
  background-color: #5A2E98 !important;
}
.sep {
  margin: 20% 45% 0;
  display:inline-block;
  
  font-size: 4em;
  
  border: 3px solid ;
  border-color: #5A2E98;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}
@keyframes blinking {
  50% {
    border-color: #ffcd5f;
  }
}
p.inline {
  display: inline-block !important;
}

.btn-ep2 {
  padding: 2px 5px !important;
  background: #5A2E98 !important;
  color: white !important;
  width: fit-content;
  font-weight: 400 !important;
  margin: 0px 10px !important;
  max-width: fit-content !important;
}
.load-anime {
  position: relative;
  width: 100%;
  min-height: 600px ;
}
.play{
  position: absolute;
  bottom: 0;
  left: 10%;
  background-color: white !important;
  font-weight: 900;
  font-size: 20px;
  padding: 5px 25px !important;
}
.productSingle__price {
  display: flex;
  align-items: baseline;
  margin: .25rem;

}
.price {
  font-weight: 900;
  margin: 0 .5rem 0 0;
  line-height: 1.2;
  font-size: 30px !important;
  
}
.pro-span {
  padding: 0px 0px 0px 13px;
}
.productSingle__image{
  overflow: hidden;
  position: relative;
  margin-left: auto;
  height: 60vh !important;
margin-right: auto;
left: 0;
right: 0;
}

.productSingle__image img {
  width: 100%;
  height:60vh;
  position: absolute;
  object-fit: contain;
 
}
.productSingle__details {
  padding: 3rem 1rem 3rem 3rem;
  display: flex;
  flex-flow: column nowrap;
}
.shadow-sm {
  background: #191919 !important;
}
.productSingle__name{
font-size: 22px;
margin-top: 0;
margin-bottom: 0.5rem;
color: white;
font-weight: 700;
line-height: 1.2;
}
.productSingle__features{
  position: relative;
  padding: 0;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-size: 18px;
}
.top-line{

  
  color: white;
  font-weight: 700;
  font-size: 1rem;
  line-height: 0px;

}
.top-line2{


  
  font-weight: 700;
  font-size: 1.5rem;

}
.add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.head-start {
  display: none;
}

.anime_video_body_watch_items {
  position: relative;
}
.anime_video_body_watch_items {
  margin: 10px 0;
}
.play-video {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  background-color: #000;
}

.imgg {
  position: relative;
}
.css-wu4cv8{
  overflow-x: hidden !important;
        overflow-y: auto !important;
max-height: 100vh !important;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;
}

@media (max-width: 800px){
  body {
      height: auto;
  }
 
  .productSingle {
    padding: 10px 20px 0px 0px !important;
}
  .header{
    margin-bottom: 400px;
  
  }
  .form-label {
    width: 18% !important;
}

}



@media (max-width: 550px){
  .tag {

    font-size: 7px;
  
}
.hide-phone{
  display: none !important;
}
.load-anime {
  position: relative;
  width: 100%;
  min-height: 400px !important;
}
  .responsive-iframe {

    width: 100% !important;
}


.ep-button {
  display: flex;
  flex-flow: row wrap!important;
  gap: 5px !important;
  padding: 10px 10px !important;
}
.episodes {
  background: #000;
  margin: 0 10px;
}
  .play-video {
    position: relative;
    overflow: hidden;
    padding-bottom: 70.25%;
    height: 0;
    width: 100%;
    background-color: #000;
  }
  
  body {
      height: auto;
  }
  .anime_video_body_watch_items .play-video {
    padding: 0;
    min-height: 400px;
    height: 600px;
    background-color: #000;
}
  .head-conatiner {
    display: flex;
    gap: 20px;
    height: 70px;
    justify-content: center;
    align-items: center;
}

  .home-page {

    padding-left: 0px !important;
  }
  .cart-page {
    padding-left: 0px !important;
  }
  
  .productSingle {
    padding: 3rem 1.5rem !important;
}
.productSingle__inner {
  flex-flow: column;
  
}
.productSingle__image {
  flex: 0 0 100%;
  padding-bottom: 133%;
}
.productSingle__details {
  padding: 3rem 0;
}
.banner {
  padding: 0px !important;
  position: relative;
  list-style: none;
  width: 100%;
  border-radius: 0px !important;
  padding-right: 0px !important;
}
.cart-page {
  padding-left: 0px !important;
  
}



}

